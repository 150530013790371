import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import { Media } from '@lp-lib/media';
import { Block, BlockType } from './block';

export interface TeamDataList<T> {
  [teamId: string]: T;
}

export enum QuestionBlockGameSessionStatus {
  LOADED = 0,
  PRESENTING = 1,
  COUNTING = 2,
  TIMESUP = 3,
  ANSWER = 4,
  SCOREBOARD = 5,
  END = 6,
}

export enum RapidBlockGameSessionStatus {
  LOADED = 0,
  PRESENTING = 1,
  QUESTION_COUNTING = 2,
  QUESTION_END = 3,
  RESULTS = 4,
  POINTS_DISTRIBUTED = 5,
  SCOREBOARD = 6,
  END = 7,
}

export enum CreativePromptBlockGameSessionStatus {
  LOADED = 0,
  PRESENTING = 1,
  SUBMISSION_COUNTING = 2,
  SUBMISSION_END = 3,
  SHOW_SUBMISSIONS = 4,
  VOTE_COUNTING = 5,
  VOTE_END = 6,
  RESULTS = 7,
  POINTS_DISTRIBUTED = 8,
  SCOREBOARD = 9,
  END = 10,
}

export enum TitleBlockGameSessionStatus {
  LOADED = 0,
  PRESENTING = 1,
  END = 2,
}

export enum ScoreboardBlockGameSessionStatus {
  LOADED,
  PRESENTING,
  SCOREBOARD,
  END,
}

export enum TeamRelayBlockGameSessionStatus {
  LOADED,
  INTRO,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum SpotlightBlockGameSessionStatus {
  LOADED = 0,
  PRESENTING = 1,
  CELEBRATING = 2,
  END = 3,
}

export enum SpotlightBlockV2GameSessionStatus {
  LOADED,
  PRESENTING,
  CELEBRATING,
  RESULTS,
  END,
}

export enum RandomizerGameSessionStatus {
  LOADED,
  STARTING,
  RESULT,
  END,
}

export enum MultipleChoiceGameSessionStatus {
  LOADED,
  PRESENTING_QUESTION,
  PRESENTED_QUESTION,
  SUBMISSION_TIMER_COUNTING,
  SUBMISSION_TIMER_DONE,
  PRESENTING_ANSWER,
  PRESENTED_ANSWER,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum MemoryMatchBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_START,
  GAME_END,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum PuzzleBlockGameSessionStatus {
  LOADED,
  INTRO,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum RoundRobinQuestionBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_START,
  GAME_END,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum TitleBlockV2GameSessionStatus {
  LOADED,
  // From PRESENT_START up to PRESENT_END, each status represents a corresponding title card.
  // Eg. status === 5, means should display 5th title card.
  PRESENT_START = 1,
  PRESENT_END = 1000,
  END = 9999,
}

export enum InstructionBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_START,
  GAME_END,
  END,
}

export enum OverRoastedBlockGameSessionStatus {
  LOADED,
  INTRO,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum DrawingPromptBlockGameSessionStatus {
  LOADED,
  INIT,
  DRAWING_START,
  DRAWING_END,
  TEAM_VOTE_COUNTING,
  TEAM_VOTE_DONE,
  TITLE_CREATION_COUNTING,
  TITLE_CREATION_DONE,
  MATCH_PROMPT_INIT,
  MATCH_PROMPT_COUNTING,
  MATCH_PROMPT_DONE,
  REVIEW_ALL_DRAWINGS,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum HiddenPictureBlockGameSessionStatus {
  LOADED,
  INTRO,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum AIChatBlockGameSessionStatus {
  LOADED,
  INTRO,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum GuessWhoBlockGameSessionStatus {
  LOADED,
  INTRO,
  PROMPT_INIT,
  PROMPT_COUNTING,
  PROMPT_DONE,
  MATCH_PROMPT_INIT,
  MATCH_PROMPT_COUNTING,
  MATCH_PROMPT_DONE,
  RESULTS,
  SCOREBOARD,
  END,
}

export enum IcebreakerBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_START,
  RESULTS,
  END,
}

export enum MarketingBlockGameSessionStatus {
  LOADED,
  PRESENTING,
  END,
}

export enum JeopardyBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_START,
  GAME_END,
  OUTRO,
  RESULTS,
  END,
}

export enum HeadToHeadBlockGameSessionStatus {
  LOADED,
  GAME_INIT,
  GAME_INTRO,
  GAME_START,
  GAME_END,
  RESULTS,
  END,
}

export enum SlideBlockGameSessionStatus {
  LOADED,
  PRESENTING,
  END,
}

export enum DrawToWinBlockGameSessionStatus {
  LOADED,
  PRESENTING,
  END,
}

export enum RoleplayBlockGameSessionStatus {
  LOADED,
  INTRO,
  PLAYING,
  END,
}

export type GameSessionStatus =
  | QuestionBlockGameSessionStatus
  | RapidBlockGameSessionStatus
  | CreativePromptBlockGameSessionStatus
  | TitleBlockGameSessionStatus
  | ScoreboardBlockGameSessionStatus
  | SpotlightBlockGameSessionStatus
  | SpotlightBlockV2GameSessionStatus
  | TeamRelayBlockGameSessionStatus
  | RandomizerGameSessionStatus
  | MultipleChoiceGameSessionStatus
  | MemoryMatchBlockGameSessionStatus
  | PuzzleBlockGameSessionStatus
  | RoundRobinQuestionBlockGameSessionStatus
  | TitleBlockV2GameSessionStatus
  | InstructionBlockGameSessionStatus
  | OverRoastedBlockGameSessionStatus
  | DrawingPromptBlockGameSessionStatus
  | HiddenPictureBlockGameSessionStatus
  | AIChatBlockGameSessionStatus
  | GuessWhoBlockGameSessionStatus
  | IcebreakerBlockGameSessionStatus
  | MarketingBlockGameSessionStatus
  | JeopardyBlockGameSessionStatus
  | HeadToHeadBlockGameSessionStatus
  | null;

export enum QuestionBlockAnswerGrade {
  NONE = 0,
  CORRECT = 1,
  HALF = 2,
  INCORRECT = 3,
}

export type QuestionBlockAnswerGradeModel = 'Preset' | 'GPTv3' | 'GPTv4';

export enum RapidBlockAnswerGrade {
  NONE = 0,
  CORRECT = 1,
  DUPLICATE = 2,
  WRONG = 3,
}

export interface AggregatedAnswer {
  submitters: {
    submitterUid: string;
    teamId: string;
  }[];
  grade: QuestionBlockAnswerGrade;
  submittedAt: number;
  answer?: string;
  model?: QuestionBlockAnswerGradeModel;
}

export interface AggregatedAnswers {
  [answer: string]: AggregatedAnswer;
}

export type BaseDetailScore = {
  score: number | null;
  scoredAt?: number | RTDBServerValueTIMESTAMP | null;
  submittedAt: number;
  scoreOverride?: number | null;
  previewPoints?: number | null;
  submitterUid?: string;
};

export type QuestionBlockDetailScore = BaseDetailScore & {
  answer?: string;
  grade?: QuestionBlockAnswerGrade;
  timerWhenSubmitted?: number;
  model?: QuestionBlockAnswerGradeModel;
};

export type RapidBlockDetailScore = BaseDetailScore & {
  corrects?: number;
};

export type CreativePromptBlockDetailScore = BaseDetailScore & {
  answer?: string;
  votes?: number;
};

export type TeamRelayBlockDetailScore = BaseDetailScore;

// Note(falcon): see the note below in GameSessionPlayerData. we will reuse the
// answer field to grade multiple choice answers rather than use the index of
// the selected choice.
export type MultipleChoiceBlockDetailScore = QuestionBlockDetailScore;

export type MemoryMatchBlockDetailScore = BaseDetailScore;

export type PuzzleBlockDetailScore = BaseDetailScore;

export type RoundRobinQuestionBlockDetailScore = BaseDetailScore;

export type OverRoastedBlockDetailScore = BaseDetailScore;

export type SpotlightBlockDetailScore = BaseDetailScore;
export type DrawingPromptBlockDetailScore = BaseDetailScore;
export type HiddenPictureBlockDetailScore = BaseDetailScore;
export type GuessWhoBlockDetailScore = BaseDetailScore;
export type JeopardyBlockDetailScore = BaseDetailScore;

export type BlockDetailScore =
  | QuestionBlockDetailScore
  | RapidBlockDetailScore
  | CreativePromptBlockDetailScore
  | TeamRelayBlockDetailScore
  | MultipleChoiceBlockDetailScore
  | MemoryMatchBlockDetailScore
  | PuzzleBlockDetailScore
  | RoundRobinQuestionBlockDetailScore
  | OverRoastedBlockDetailScore
  | SpotlightBlockDetailScore
  | DrawingPromptBlockDetailScore
  | HiddenPictureBlockDetailScore
  | GuessWhoBlockDetailScore
  | JeopardyBlockDetailScore;

// game-session-player-data/{venueId}/{blockId}/{userId}
export interface GameSessionPlayerData {
  // Note(falcon): the multiple choice block reuses this field for grading. this
  // is a stop gap solution to avoid introducing new fields into this data
  // structure. rather than having a single "player data" structure for user
  // submissions, we should evolve this concept to be typed to specific kinds of
  // blocks.
  answer: string | null | undefined;
  submittedAt: RTDBServerValueTIMESTAMP | number;
  timerWhenSubmitted?: number;
  teamId: string | null | undefined;
}

// game-session-scores/{venueId}/{blockId}/{teamId}
export interface GameSessionDetailScore {
  [blockId: string]: TeamDataList<BlockDetailScore>;
}

// game-session-score-summary/{venueId}/{teamId}
export interface GameSessionScoreSummary {
  totalScore: number;
  prevScore: number;
  currentBlockScore: number | null;
}

export interface TeamScoreboardData {
  rank?: number;
  score: number;
  currentScore: number | null;
  teamId: string;
  teamName: string;
  orgName?: string | null;
  orgLogo?: Media | null;
  shortNames?: string;
  fullNames?: string;
  createdTimestamp: number;
  history?: boolean;
}

interface GameSessionStatusMap {
  loaded: number;
  intro: number;
  introMediaRetained: readonly number[];
  gameStart: number | null;
  gameEnd: number | null;
  scoreboard: number | null;
  counting: readonly number[];
  pointDistributed: number | null;
  pointDistributedSafe?: number;
  outro: number | null;
  end: number;
}

const GAME_SESSION_STATUS_MAP: {
  [key in BlockType]: GameSessionStatusMap;
} = {
  [BlockType.CREATIVE_PROMPT]: {
    loaded: CreativePromptBlockGameSessionStatus.LOADED,
    intro: CreativePromptBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [
      CreativePromptBlockGameSessionStatus.PRESENTING,
      CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING,
      CreativePromptBlockGameSessionStatus.SUBMISSION_END,
    ],
    gameStart: CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING,
    gameEnd: CreativePromptBlockGameSessionStatus.SUBMISSION_END,
    counting: [
      CreativePromptBlockGameSessionStatus.PRESENTING,
      CreativePromptBlockGameSessionStatus.SUBMISSION_COUNTING,
      CreativePromptBlockGameSessionStatus.SHOW_SUBMISSIONS,
      CreativePromptBlockGameSessionStatus.VOTE_COUNTING,
    ],
    scoreboard: CreativePromptBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: CreativePromptBlockGameSessionStatus.POINTS_DISTRIBUTED,
    outro: null,
    end: CreativePromptBlockGameSessionStatus.END,
  },
  [BlockType.QUESTION]: {
    loaded: QuestionBlockGameSessionStatus.LOADED,
    intro: QuestionBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [
      QuestionBlockGameSessionStatus.PRESENTING,
      QuestionBlockGameSessionStatus.COUNTING,
      QuestionBlockGameSessionStatus.TIMESUP,
    ],
    gameStart: QuestionBlockGameSessionStatus.COUNTING,
    gameEnd: QuestionBlockGameSessionStatus.TIMESUP,
    counting: [
      QuestionBlockGameSessionStatus.PRESENTING,
      QuestionBlockGameSessionStatus.COUNTING,
    ],
    scoreboard: QuestionBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: QuestionBlockGameSessionStatus.ANSWER,
    outro: QuestionBlockGameSessionStatus.ANSWER,
    end: QuestionBlockGameSessionStatus.END,
  },
  [BlockType.RAPID]: {
    loaded: RapidBlockGameSessionStatus.LOADED,
    intro: RapidBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [
      RapidBlockGameSessionStatus.PRESENTING,
      RapidBlockGameSessionStatus.QUESTION_COUNTING,
    ],
    gameStart: RapidBlockGameSessionStatus.QUESTION_COUNTING,
    gameEnd: RapidBlockGameSessionStatus.QUESTION_END,
    counting: [
      RapidBlockGameSessionStatus.PRESENTING,
      RapidBlockGameSessionStatus.QUESTION_COUNTING,
    ],
    scoreboard: RapidBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: RapidBlockGameSessionStatus.POINTS_DISTRIBUTED,
    pointDistributedSafe: RapidBlockGameSessionStatus.RESULTS,
    outro: RapidBlockGameSessionStatus.QUESTION_END,
    end: RapidBlockGameSessionStatus.END,
  },
  [BlockType.SCOREBOARD]: {
    loaded: ScoreboardBlockGameSessionStatus.LOADED,
    intro: ScoreboardBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: ScoreboardBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: null,
    outro: null,
    end: ScoreboardBlockGameSessionStatus.END,
  },
  [BlockType.RANDOMIZER]: {
    loaded: RandomizerGameSessionStatus.LOADED,
    intro: RandomizerGameSessionStatus.STARTING,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: RandomizerGameSessionStatus.END,
  },
  [BlockType.SPOTLIGHT]: {
    loaded: SpotlightBlockGameSessionStatus.LOADED,
    intro: SpotlightBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [SpotlightBlockGameSessionStatus.PRESENTING],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: SpotlightBlockGameSessionStatus.END,
  },
  [BlockType.SPOTLIGHT_V2]: {
    loaded: SpotlightBlockV2GameSessionStatus.LOADED,
    intro: SpotlightBlockV2GameSessionStatus.PRESENTING,
    introMediaRetained: [SpotlightBlockV2GameSessionStatus.PRESENTING],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: SpotlightBlockV2GameSessionStatus.RESULTS,
    outro: null,
    end: SpotlightBlockV2GameSessionStatus.END,
  },
  [BlockType.TEAM_RELAY]: {
    loaded: TeamRelayBlockGameSessionStatus.LOADED,
    intro: TeamRelayBlockGameSessionStatus.INTRO,
    introMediaRetained: [TeamRelayBlockGameSessionStatus.INTRO],
    gameStart: TeamRelayBlockGameSessionStatus.GAME_START,
    gameEnd: TeamRelayBlockGameSessionStatus.GAME_END,
    counting: [
      TeamRelayBlockGameSessionStatus.INTRO,
      TeamRelayBlockGameSessionStatus.GAME_START,
      TeamRelayBlockGameSessionStatus.OUTRO,
    ],
    scoreboard: TeamRelayBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: TeamRelayBlockGameSessionStatus.RESULTS,
    outro: TeamRelayBlockGameSessionStatus.OUTRO,
    end: TeamRelayBlockGameSessionStatus.END,
  },
  [BlockType.MULTIPLE_CHOICE]: {
    loaded: MultipleChoiceGameSessionStatus.LOADED,
    intro: MultipleChoiceGameSessionStatus.PRESENTING_QUESTION,
    introMediaRetained: [
      MultipleChoiceGameSessionStatus.PRESENTING_QUESTION,
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE,
    ],
    gameStart: MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
    gameEnd: MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_DONE,
    counting: [
      MultipleChoiceGameSessionStatus.PRESENTING_QUESTION,
      MultipleChoiceGameSessionStatus.SUBMISSION_TIMER_COUNTING,
    ],
    scoreboard: MultipleChoiceGameSessionStatus.SCOREBOARD,
    pointDistributed: MultipleChoiceGameSessionStatus.RESULTS,
    outro: MultipleChoiceGameSessionStatus.PRESENTING_ANSWER,
    end: MultipleChoiceGameSessionStatus.END,
  },
  [BlockType.MEMORY_MATCH]: {
    loaded: MemoryMatchBlockGameSessionStatus.LOADED,
    intro: MemoryMatchBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [MemoryMatchBlockGameSessionStatus.GAME_INIT],
    gameStart: MemoryMatchBlockGameSessionStatus.GAME_START,
    gameEnd: MemoryMatchBlockGameSessionStatus.GAME_END,
    counting: [
      MemoryMatchBlockGameSessionStatus.GAME_INIT,
      MemoryMatchBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: MemoryMatchBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: MemoryMatchBlockGameSessionStatus.RESULTS,
    outro: null,
    end: MemoryMatchBlockGameSessionStatus.END,
  },
  [BlockType.PUZZLE]: {
    loaded: PuzzleBlockGameSessionStatus.LOADED,
    intro: PuzzleBlockGameSessionStatus.INTRO,
    introMediaRetained: [PuzzleBlockGameSessionStatus.INTRO],
    gameStart: PuzzleBlockGameSessionStatus.GAME_START,
    gameEnd: PuzzleBlockGameSessionStatus.GAME_END,
    counting: [
      PuzzleBlockGameSessionStatus.GAME_INIT,
      PuzzleBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: PuzzleBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: PuzzleBlockGameSessionStatus.RESULTS,
    outro: PuzzleBlockGameSessionStatus.OUTRO,
    end: PuzzleBlockGameSessionStatus.END,
  },
  [BlockType.ROUND_ROBIN_QUESTION]: {
    loaded: RoundRobinQuestionBlockGameSessionStatus.LOADED,
    intro: RoundRobinQuestionBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [RoundRobinQuestionBlockGameSessionStatus.GAME_INIT],
    gameStart: RoundRobinQuestionBlockGameSessionStatus.GAME_START,
    gameEnd: RoundRobinQuestionBlockGameSessionStatus.GAME_END,
    counting: [
      RoundRobinQuestionBlockGameSessionStatus.GAME_INIT,
      RoundRobinQuestionBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: RoundRobinQuestionBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: RoundRobinQuestionBlockGameSessionStatus.RESULTS,
    outro: null,
    end: RoundRobinQuestionBlockGameSessionStatus.END,
  },
  [BlockType.TITLE_V2]: {
    loaded: TitleBlockV2GameSessionStatus.LOADED,
    intro: TitleBlockV2GameSessionStatus.PRESENT_START,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: TitleBlockV2GameSessionStatus.END,
  },
  [BlockType.INSTRUCTION]: {
    loaded: InstructionBlockGameSessionStatus.LOADED,
    intro: InstructionBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [
      InstructionBlockGameSessionStatus.GAME_INIT,
      InstructionBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: InstructionBlockGameSessionStatus.END,
  },
  [BlockType.OVERROASTED]: {
    loaded: OverRoastedBlockGameSessionStatus.LOADED,
    intro: OverRoastedBlockGameSessionStatus.INTRO,
    introMediaRetained: [],
    gameStart: OverRoastedBlockGameSessionStatus.GAME_START,
    gameEnd: OverRoastedBlockGameSessionStatus.GAME_END,
    counting: [
      OverRoastedBlockGameSessionStatus.GAME_INIT,
      OverRoastedBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: OverRoastedBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: OverRoastedBlockGameSessionStatus.RESULTS,
    outro: OverRoastedBlockGameSessionStatus.OUTRO,
    end: OverRoastedBlockGameSessionStatus.END,
  },
  [BlockType.DRAWING_PROMPT]: {
    loaded: DrawingPromptBlockGameSessionStatus.LOADED,
    intro: DrawingPromptBlockGameSessionStatus.INIT,
    introMediaRetained: [],
    // intuitively speaking, the gameStart should be mapped to DRAWING_START,
    // however gameStart is used to control the townhall mode that switchs teams
    // back to townhall once they finish the game. (useOndV3TeamSubmittedTrigger)
    // In drawing game, this is triggerd after title creation phase, the first
    // two phases are always in team mode.
    gameStart: DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING,
    gameEnd: DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE,
    counting: [
      DrawingPromptBlockGameSessionStatus.INIT,
      DrawingPromptBlockGameSessionStatus.DRAWING_START,
      DrawingPromptBlockGameSessionStatus.DRAWING_END,
      DrawingPromptBlockGameSessionStatus.TEAM_VOTE_COUNTING,
      DrawingPromptBlockGameSessionStatus.TEAM_VOTE_DONE,
      DrawingPromptBlockGameSessionStatus.TITLE_CREATION_COUNTING,
      DrawingPromptBlockGameSessionStatus.TITLE_CREATION_DONE,
      DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
      DrawingPromptBlockGameSessionStatus.MATCH_PROMPT_DONE,
    ],
    scoreboard: DrawingPromptBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: DrawingPromptBlockGameSessionStatus.RESULTS,
    outro: null,
    end: DrawingPromptBlockGameSessionStatus.END,
  },
  [BlockType.HIDDEN_PICTURE]: {
    loaded: HiddenPictureBlockGameSessionStatus.LOADED,
    intro: HiddenPictureBlockGameSessionStatus.INTRO,
    introMediaRetained: [HiddenPictureBlockGameSessionStatus.INTRO],
    gameStart: HiddenPictureBlockGameSessionStatus.GAME_START,
    gameEnd: HiddenPictureBlockGameSessionStatus.GAME_END,
    counting: [
      HiddenPictureBlockGameSessionStatus.GAME_INIT,
      HiddenPictureBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: HiddenPictureBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: HiddenPictureBlockGameSessionStatus.RESULTS,
    outro: HiddenPictureBlockGameSessionStatus.OUTRO,
    end: HiddenPictureBlockGameSessionStatus.END,
  },
  [BlockType.AI_CHAT]: {
    loaded: AIChatBlockGameSessionStatus.LOADED,
    intro: AIChatBlockGameSessionStatus.INTRO,
    introMediaRetained: [],
    gameStart: AIChatBlockGameSessionStatus.GAME_START,
    gameEnd: AIChatBlockGameSessionStatus.GAME_END,
    counting: [
      AIChatBlockGameSessionStatus.GAME_INIT,
      AIChatBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: AIChatBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: AIChatBlockGameSessionStatus.RESULTS,
    outro: AIChatBlockGameSessionStatus.OUTRO,
    end: AIChatBlockGameSessionStatus.END,
  },
  [BlockType.ICEBREAKER]: {
    loaded: IcebreakerBlockGameSessionStatus.LOADED,
    intro: IcebreakerBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [],
    gameStart: IcebreakerBlockGameSessionStatus.GAME_START,
    gameEnd: IcebreakerBlockGameSessionStatus.RESULTS,
    counting: [],
    scoreboard: null,
    pointDistributed: IcebreakerBlockGameSessionStatus.RESULTS,
    outro: null,
    end: IcebreakerBlockGameSessionStatus.END,
  },
  [BlockType.GUESS_WHO]: {
    loaded: GuessWhoBlockGameSessionStatus.LOADED,
    intro: GuessWhoBlockGameSessionStatus.INTRO,
    introMediaRetained: [
      GuessWhoBlockGameSessionStatus.PROMPT_INIT,
      GuessWhoBlockGameSessionStatus.PROMPT_COUNTING,
      GuessWhoBlockGameSessionStatus.PROMPT_DONE,
    ],
    gameStart: GuessWhoBlockGameSessionStatus.PROMPT_COUNTING,
    gameEnd: GuessWhoBlockGameSessionStatus.PROMPT_DONE,
    counting: [
      GuessWhoBlockGameSessionStatus.PROMPT_COUNTING,
      GuessWhoBlockGameSessionStatus.PROMPT_DONE,
      GuessWhoBlockGameSessionStatus.MATCH_PROMPT_COUNTING,
      GuessWhoBlockGameSessionStatus.MATCH_PROMPT_DONE,
    ],
    scoreboard: GuessWhoBlockGameSessionStatus.SCOREBOARD,
    pointDistributed: GuessWhoBlockGameSessionStatus.RESULTS,
    outro: null,
    end: GuessWhoBlockGameSessionStatus.END,
  },
  [BlockType.MARKETING]: {
    loaded: MarketingBlockGameSessionStatus.LOADED,
    intro: MarketingBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: MarketingBlockGameSessionStatus.END,
  },
  [BlockType.JEOPARDY]: {
    loaded: JeopardyBlockGameSessionStatus.LOADED,
    intro: JeopardyBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [],
    gameStart: JeopardyBlockGameSessionStatus.GAME_START,
    gameEnd: JeopardyBlockGameSessionStatus.GAME_END,
    counting: [
      JeopardyBlockGameSessionStatus.GAME_INIT,
      JeopardyBlockGameSessionStatus.GAME_START,
    ],
    scoreboard: null,
    pointDistributed: JeopardyBlockGameSessionStatus.RESULTS,
    outro: JeopardyBlockGameSessionStatus.OUTRO,
    end: JeopardyBlockGameSessionStatus.END,
  },
  [BlockType.HEAD_TO_HEAD]: {
    loaded: HeadToHeadBlockGameSessionStatus.LOADED,
    intro: HeadToHeadBlockGameSessionStatus.GAME_INIT,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: HeadToHeadBlockGameSessionStatus.RESULTS,
    outro: null,
    end: HeadToHeadBlockGameSessionStatus.END,
  },
  [BlockType.SLIDE]: {
    loaded: RapidBlockGameSessionStatus.LOADED,
    intro: RapidBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: RapidBlockGameSessionStatus.END,
  },
  [BlockType.DRAW_TO_WIN]: {
    loaded: DrawToWinBlockGameSessionStatus.LOADED,
    intro: DrawToWinBlockGameSessionStatus.PRESENTING,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: DrawToWinBlockGameSessionStatus.END,
  },
  [BlockType.ROLEPLAY]: {
    loaded: RoleplayBlockGameSessionStatus.LOADED,
    intro: RoleplayBlockGameSessionStatus.INTRO,
    introMediaRetained: [],
    gameStart: null,
    gameEnd: null,
    counting: [],
    scoreboard: null,
    pointDistributed: null,
    outro: null,
    end: RoleplayBlockGameSessionStatus.END,
  },
} as const;

for (const [kind, map] of Object.entries(GAME_SESSION_STATUS_MAP)) {
  if (map.loaded !== 0)
    throw new Error(
      `GameSessionStatusMap "loaded" for ${kind} must be 0 due to assumptions in the OnD gameplay system`
    );
  if (map.intro !== 1)
    throw new Error(
      `GameSessionStatusMap "intro" for ${kind} must be 1 due to assumptions in the OnD gameplay system`
    );

  const maxStatus = Math.max(
    ...Object.values(map).reduce((all, s) => {
      if (s === null) return all;
      return all.concat(s);
    }, [])
  );
  if (map.end !== maxStatus)
    throw new Error(
      `GameSessionStatusMap "end" for ${kind} must be the max value due to assumptions in the OnD gameplay system. Max: ${maxStatus}`
    );
}

export class GameSessionUtil {
  static StatusMapFor(
    block: Block | BlockType | null | undefined
  ): GameSessionStatusMap | null {
    const kind = !block
      ? null
      : typeof block !== 'string' && 'type' in block
      ? block.type
      : block;

    if (kind === null) return null;
    return GAME_SESSION_STATUS_MAP[kind] ?? null;
  }

  static IsMidGame(
    block: Block | BlockType | null | undefined,
    status: GameSessionStatus
  ): boolean {
    const statusMap = GameSessionUtil.StatusMapFor(block);
    if (
      status === null ||
      !statusMap ||
      statusMap.gameStart === null ||
      statusMap.gameEnd === null
    )
      return false;

    return status >= statusMap.gameStart && status < statusMap.gameEnd;
  }
}
